<template>
  <div class="g-section order-container">
    <div class="g-wrap order-top">
      <div class="flex">
        <!-- <span>筛选</span> -->
        <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" size="small">
        </el-input>
      </div>
      <el-button type="primary" @click="$router.push('/school/order/mine')" size="small">我的订单</el-button>
    </div>
    <div class="g-wrap order-bottom">
      <div v-for="i in 6" class="order-item" :key="i">
        <div class="item-top">
          <div class="item-row-title">
            <div class="item-left">
              <span class="order-item-title">人才需求定单{{i}}200（人）</span>
            </div>
            <div class="item-right">
              深圳智邻科技有限公司
            </div>
          </div>
          <div class="item-job">
            <div class="item-left">
              <span class="mb-10">岗位1|5k-6k|专科及以上|深圳|计算机相关专业|100人</span>
            </div>
            <div class="item-right">
            </div>
          </div>
          <div class="item-job">
            <div class="item-left">
              <span class="mb-10">岗位2|3-5k|专科及以上|深圳|100人</span>
            </div>
            <div class="item-right">
            </div>
          </div>
        </div>
        <div class="item-bottom">
          <div>
            <span class="mr-20">发布时间：2020-12-31</span>
            <span class="mr-20">6所学校想接</span>
          </div>
          <div>
            <el-button type="text" icon="el-icon-search" size="small">查看</el-button>
            <el-button type="text" icon="el-icon-phone-outline" size="small" @click="$router.push('/school/chat')">沟通</el-button>
            <el-button type="text" icon="el-icon-check" size="small" @click="dialogVisible=true">接订单</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="填写申请信息" :visible.sync="dialogVisible" width="980px" :before-close="handleClose">

      <div class="dialog-content">
        <el-input v-model="remark" type="textarea" placeholder="请输入您的附加信息或接单理由" :autosize="{minRows: 5, maxRows: 5}"
          :style="{width: '100%'}"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">提交申请</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      remark:'',
      dialogVisible:false
    }
  }
}
</script>
<style lang="scss" scoped>
.order-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.order-top {
  justify-content: space-between;
  margin-top: 20px;
}
.order-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.order-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e7e8ea;
  border-radius: 3px;
  .item-top {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item-row-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .order-item-title {
      display: flex;
      text-align: left;
      font-weight: 600;
    }
    .item-job {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #e7e8ea;
    }
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .item-right {
      display: flex;
      align-items: center;
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-bottom {
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #fbfbfb;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .el-button {
    padding: 0px;
  }
}
</style>